export function CloseIcon() {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.07265 3.59999C4.94704 3.59999 4.8213 3.64784 4.72557 3.74381L3.74378 4.7256C3.55184 4.91754 3.55184 5.22831 3.74378 5.41976L7.32387 8.99985L3.74378 12.5799C3.55184 12.7719 3.55184 13.0827 3.74378 13.2741L4.72557 14.2559C4.91751 14.4478 5.22828 14.4478 5.41973 14.2559L8.99982 10.6758L12.5799 14.2559C12.7714 14.4478 13.0826 14.4478 13.2741 14.2559L14.2559 13.2741C14.4478 13.0822 14.4478 12.7714 14.2559 12.5799L10.6758 8.99985L14.2559 5.41976C14.4478 5.22831 14.4478 4.91705 14.2559 4.7256L13.2741 3.74381C13.0821 3.55187 12.7714 3.55187 12.5799 3.74381L8.99982 7.3239L5.41973 3.74381C5.32376 3.64784 5.19826 3.59999 5.07265 3.59999Z'
				fill='black'
			/>
		</svg>
	);
}
