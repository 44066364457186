

export function CalendarIcon() {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0 1.8C0 0.805888 0.805888 0 1.8 0H16.2C17.1941 0 18 0.805888 18 1.8V16.2C18 17.1941 17.1941 18 16.2 18H1.8C0.805888 18 0 17.1941 0 16.2V1.8Z'
				fill='#14AAFF'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.2 0.6H1.8C1.13726 0.6 0.6 1.13726 0.6 1.8V16.2C0.6 16.8627 1.13726 17.4 1.8 17.4H16.2C16.8627 17.4 17.4 16.8627 17.4 16.2V1.8C17.4 1.13726 16.8627 0.6 16.2 0.6ZM1.8 0C0.805888 0 0 0.805888 0 1.8V16.2C0 17.1941 0.805888 18 1.8 18H16.2C17.1941 18 18 17.1941 18 16.2V1.8C18 0.805888 17.1941 0 16.2 0H1.8Z'
				fill='#14AAFF'
			/>
			<path
				d='M12.2486 8.5636V13.8H11.1415V9.61445H11.1108L9.91162 10.3662V9.38434L11.2079 8.5636H12.2486Z'
				fill='white'
			/>
			<path
				d='M7.15993 13.8716C6.77811 13.8716 6.43805 13.806 6.13976 13.6747C5.84317 13.5418 5.60879 13.3594 5.43663 13.1276C5.26618 12.8941 5.17839 12.6247 5.17328 12.3196H6.28805C6.29487 12.4475 6.33663 12.56 6.41334 12.6571C6.49174 12.7526 6.59572 12.8267 6.72527 12.8796C6.85481 12.9324 7.00055 12.9588 7.16248 12.9588C7.33123 12.9588 7.48038 12.929 7.60993 12.8693C7.73947 12.8097 7.84089 12.727 7.91419 12.6213C7.98748 12.5156 8.02413 12.3938 8.02413 12.2557C8.02413 12.1159 7.98493 11.9924 7.90652 11.885C7.82981 11.7759 7.71902 11.6906 7.57413 11.6293C7.43095 11.5679 7.26049 11.5372 7.06277 11.5372H6.57442V10.7242H7.06277C7.22981 10.7242 7.37726 10.6952 7.5051 10.6372C7.63464 10.5793 7.73521 10.4992 7.8068 10.3969C7.87839 10.2929 7.91419 10.1719 7.91419 10.0338C7.91419 9.90258 7.88265 9.78752 7.81959 9.68866C7.75822 9.58809 7.67129 9.50968 7.55879 9.45343C7.44799 9.39718 7.31845 9.36905 7.17015 9.36905C7.02015 9.36905 6.88294 9.39633 6.75851 9.45087C6.63407 9.50371 6.53436 9.57957 6.45936 9.67843C6.38436 9.77729 6.3443 9.8932 6.33919 10.0262H5.27811C5.28322 9.72445 5.3693 9.45854 5.53635 9.22843C5.70339 8.99832 5.92839 8.81849 6.21135 8.68894C6.49601 8.55769 6.81731 8.49207 7.17527 8.49207C7.53663 8.49207 7.85282 8.55769 8.12385 8.68894C8.39487 8.82019 8.60538 8.99746 8.75538 9.22076C8.90709 9.44235 8.98209 9.69121 8.98038 9.96735C8.98209 10.2605 8.89089 10.5051 8.7068 10.7012C8.52442 10.8972 8.28663 11.0216 7.99345 11.0745V11.1154C8.37868 11.1648 8.67186 11.2986 8.873 11.5168C9.07584 11.7333 9.1764 12.0043 9.1747 12.3299C9.1764 12.6281 9.09032 12.8932 8.91646 13.125C8.7443 13.3568 8.50652 13.5392 8.20311 13.6722C7.8997 13.8051 7.55197 13.8716 7.15993 13.8716Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 5.1H0V4.5H18V5.1Z'
				fill='white'
			/>
		</svg>
	);
}
